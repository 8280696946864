const courses = [
  {
    name: "Hospitality-Courses",
    imageUrl:
      "https://images.unsplash.com/photo-1588675646184-f5b0b0b0b2de?auto=format&fit=crop&q=80&w=3687&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description:
      "We provides complete solution from concept to execution. Our team of experienced professionals ensures to fulfill the highest expectations of all our guests.",
    link: "https://elite-collection.net/PRESENTATION%20for%20TAG%20-%20MHG%20Royal%20Protocol.pdf",
  },
  {
    name: "Food-Safety",
    description:
      "routines in the preparation, handling and storage of food meant to prevent foodborne illness and injury. From farm to factory to fork, food products may encounter any number of health hazards during their journey through the supply chain.",
    imageUrl:
      "https://images.unsplash.com/photo-1607681034512-1c9c5fbda608?auto=format&fit=crop&q=80&w=3570&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    link: "https://elite-collection.net/Food%20safety%20manual%202021.pdf",
  },
  // More people...
];

const CoursesSection = () => {
  return (
    <div className="py-24 md:py-32 lg:py-40" id="courses">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Our courses
          </h2>
          <p className="mt-6 text-lg leading-8 text-white">
            We offer a wide range of training opportunities for aspiring chefs,
            culinary professionals, and educators.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
        >
          {courses.map((course) => (
            <li key={course.name}>
              <img
                className="aspect-[3/2] w-full rounded-2xl object-cover"
                src={course.imageUrl}
                alt=""
              />
              <h3 className="mt-6 text-lg font-semibold leading-8 text-white">
                {course.name}
              </h3>
              <p className="my-4 text-base leading-7 text-white">
                {course.description}
              </p>
              <div>
                <a
                  href={course.link}
                  target="_blank"
                  className="mt-6 text-base leading-6 font-semibold bg-[#B11C41] text-white px-6 py-3 rounded-lg hover:bg-[#97182E] transition duration-150 ease-in-out"
                >
                  Learn more
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CoursesSection;
