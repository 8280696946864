import "./App.css";
import AboutTheChef from "./components/AboutTheChef";
import AboutUs from "./components/AboutUs";
import CoursesSection from "./components/CoursesSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PlatesGallerySection from "./components/PlatesGallerySection";
import TrainingSection from "./components/TrainingSection";
import VideoSection from "./components/VideoSection";

function App() {
  return (
    <div className="App bg-slate-800">
      <Header />
      <VideoSection />
      <AboutUs />
      <AboutTheChef />
      <TrainingSection />
      <PlatesGallerySection />
      <CoursesSection />
      <Footer />
    </div>
  );
}

export default App;
