import React from "react";

const AboutUs = () => {
  return (
    <div className="pt-28 overflow-hidden sm:mt-40" id="about">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl text-left font-bold tracking-tight text-white sm:text-4xl">
              About Us
            </h2>
            <p className="mt-6 text-left text-xl leading-8 text-gray-400">
              Clients enjoy the world’s most luxurious standards in food,
              organized and prepared through the President of the global chefs'
              organization/association, Master Executive Chef Thomas A. The chef
              has cooked for many famous names including Arab royalty,
              celebrities, and many different government officials in the Middle
              East and African regions..
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://images.unsplash.com/photo-1615485500704-8e990f9900f7?auto=format&fit=crop&q=80&w=3580&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="herbs"
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
