const features = [
  {
    name: "Become Worldchefs Certified",
    description:
      "Showcase your skills and unlock your career path with Global Hospitality Certification.",
    imageSrc: "https://elite-collection.net/images/training-chef-1.jpeg",
    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
  },
  {
    name: "Join aspiring Chefs around the world!",
    description:
      "Sign up today for our FREE Pre-Commis Chef Training Program or Sustainability Education for Culinary Professionals today!",
    imageSrc: "https://elite-collection.net/images/training-2.jpg",
    imageAlt: "trating 2",
  },
  {
    name: "Build a resilient food future for all",
    description:
      "Feed the Planet empowers sustainable cooking and eating among communities and culinary professionals.",
    imageSrc: "https://elite-collection.net/images/training-3.jpg",
    imageAlt: "",
  },
  {
    name: "Learn with Worldchefs",
    description:
      "Find the best culinary schools and training programs to boost your career.",
    imageSrc: "https://elite-collection.net/images/training-4.jpg",
    imageAlt: "",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TrainingSection = () => {
  return (
    <div className="pt-24">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Train with the best
          </h2>
          <p className="mt-4 text-white">
            Acquire the skills you need to succeed in the culinary industry
            today. Worldchefs program offers a wide range of training
            opportunities for aspiring chefs, culinary professionals, and
            educators.
          </p>
        </div>

        <div className="mt-16 space-y-16">
          {features.map((feature, featureIdx) => (
            <div
              key={feature.name}
              className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
            >
              <div
                className={classNames(
                  featureIdx % 2 === 0
                    ? "lg:col-start-1"
                    : "lg:col-start-8 xl:col-start-9",
                  "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                )}
              >
                <h3 className="text-lg font-medium text-white">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-white">{feature.description}</p>
              </div>
              <div
                className={classNames(
                  featureIdx % 2 === 0
                    ? "lg:col-start-6 xl:col-start-5"
                    : "lg:col-start-1",
                  "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                )}
              >
                <div className="aspect-h-2 aspect-w-5 overflow-hidden rounded-lg bg-gray-100">
                  <img
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrainingSection;
