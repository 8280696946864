import React from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

const benefits = [
  "Competitive salaries",
  "Flexible work hours",
  "30 days of paid vacation",
  "Annual team retreats",
  "Benefits for you and your family",
  "A great work environment",
];

const AboutTheChef = () => {
  return (
    <div className="pt-24 sm:pt-32">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl bg-gray-600 text-white rounded-3xl">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-gray/5 px-6 py-16 ring-1 ring-gray/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              className="h-auto w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
              src="https://elite-collection.net/images/thomas.jpeg"
              alt=""
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                About the chef
              </h2>
              <p className="mt-6 leading-8">
                A proactive, focused and committed professional, with extensive
                expertisegained within the hotel / resort / restaurant /
                catering / competition and hospitalitysector. Commercially
                astute, with the ability to identify business opportunities
                andimplement effective trainings, novelty in food art,
                creativity in menu development andmenu concepts, excellent
                leadership, HACCP and ISO certified and skilled, able to
                raisecorporate profiles and consolidate year on year expansion.
                An accomplished time manager,organizer and change manager,
                capable of introducing and integrating innovativestrategies,
                streamlining resources to maximize performance and quality
                standards toensure the ongoing provision of the highest output
                of customer satisfaction. A wellpresented, confident and
                articulate communicator and negotiator at all levels,
                whocommands respect and credibility through the projection of a
                professional
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTheChef;
