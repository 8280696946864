import React from "react";

const files = [
  {
    source: "https://elite-collection.net/images/img-t.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-2.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-3.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-4.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-5.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-6.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-7.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-8.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-9.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-10.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-11.jpeg",
  },
  {
    source: "https://elite-collection.net/images/img-t-12.jpeg",
  },
];

const PlatesGallerySection = () => {
  return (
    <div className="mx-auto max-w-2xl px-4 py-12 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
      <ul
        role="list"
        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        {files.map((file) => (
          <li key={file.source} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={file.source}
                alt=""
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">View details for {file.title}</span>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlatesGallerySection;
